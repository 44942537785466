import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <a className='link' href='http://beian.miit.gov.cn'>yixiuhealth.com 京ICP备19022034号-1</a>
    </div>
  );
}

export default App;
